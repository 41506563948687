import { PageEvent } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { BookingAggregate } from 'src/app/shared/models/booking.model';
import { BookingSearchForm } from 'src/app/shared/models/elastic.vm.model';
import { Quote } from 'src/app/shared/models/quote.model';
import { AccountUser } from '../../../../../../src/app/shared/models/account-user.model';
import { Business } from '../../shared/models/business.model';
import { DriverAggregate, DriverEta, DriverTelemetry } from '../../shared/models/driver.model';
import { SearchResponse } from '../../shared/models/elastic-api.model';
import { FirebaseUser } from '../../shared/models/firebase-user.model';
import { PartnerCompanyAggregate } from '../../shared/models/partner.model';
import { RiderAggregate } from '../../shared/models/rider.module';
import { CreateRiderReceiptFormVM } from '../../shared/models/rider.vm.model';
import { TripsSearchResponse } from '../../shared/models/trips.vm.model';
import { VehicleAggregate } from '../../shared/models/vehicle.model';

export const queryParamsChanged = createAction(
  '[Support agent trip list] Query params changed',
  props<{ queryParams: Params }>(),
);

export const tripListInit = createAction('[Support agent trip list] Init');

export const tripListDestroyed = createAction('[Support agent trip list] Destroyed');

export const searchSubmitButtonClicked = createAction(
  '[Support agent trip list] Search button clicked',
  props<{ searchForm: BookingSearchForm }>(),
);

export const autoRefreshChanged = createAction(
  '[Support agent trip list] Auto refresh changed',
  props<{ autoRefresh: boolean }>(),
);

export const autoRefreshTriggered = createAction('[Support agent trip list] Auto refresh triggered');

export const searchResponseFetched = createAction(
  '[Support agent trip list] Search response fetched',
  props<{ searchResponse: SearchResponse<TripsSearchResponse> }>(),
);

export const bookingListFetched = createAction(
  '[Support agent trip list] Booking list fetched',
  props<{ bookings: BookingAggregate[] }>(),
);

export const pageChanged = createAction('[Support agent trip list] Page changed', props<{ pageEvent: PageEvent }>());

export const notCompletedTripListDriversFetched = createAction(
  '[Support agent trip list] Not completed trip list drivers fetched',
  props<{ drivers: DriverAggregate[] }>(),
);

export const tripDetailsInit = createAction(
  '[Support agent trip details] Trip details init',
  props<{ bookingId: string }>(),
);

export const bookingAggregateFetched = createAction(
  '[Support agent trip details] Booking aggregate fetched',
  props<{ bookingAggregate: BookingAggregate }>(),
);

export const tripDetailsDestroy = createAction('[Support agent trip details] Trip details destroy');

export const navigateToIntercomUserProfileButtonClicked = createAction(
  '[Support agent trip details] Navigate to Intercom user profile button clicked',
  props<{ userId: string }>(),
);

export const navigateToIntercomB2bUserProfileButtonClicked = createAction(
  '[Support agent trip details] Navigate to Intercom B2B user profile button clicked',
  props<{ businessId: string }>(),
);

export const navigateToIntercomDriverProfileButtonClicked = createAction(
  '[Support agent trip details] Navigate to Intercom driver profile button clicked',
  props<{ driverId: string }>(),
);

export const bookingOfferDriversFetched = createAction(
  '[Support agent trip details] Booking offer drivers fetched',
  props<{ drivers: DriverAggregate[] }>(),
);

export const driverDetailsButtonClicked = createAction(
  '[Support agent trip details] Driver details button clicked',
  props<{ driverId: string }>(),
);

export const driverAggregateFetched = createAction(
  '[Support agent trip details] Driver fetched',
  props<{ driver: DriverAggregate }>(),
);

export const driverUserFetched = createAction(
  '[Support agent trip details] Driver user fetched',
  props<{ driverUser: AccountUser }>(),
);

export const vehicleAggregateFetched = createAction(
  '[Support agent trip details] Vehicle fetched',
  props<{ vehicle: VehicleAggregate }>(),
);

export const businessFetched = createAction(
  '[Support agent trip details] Business fetched',
  props<{ business: Business }>(),
);

export const riderReceiptButtonClicked = createAction('[Support agent trip details] Rider receipt button clicked');

export const riderReceiptSubmitButtonClicked = createAction(
  '[Support agent trip details] Rider receipt submit button clicked',
  props<{ payload: CreateRiderReceiptFormVM }>(),
);

export const riderReceiptCreated = createAction('[Support agent trip details] Rider receipt created');

export const riderReceiptCreateFailed = createAction('[Support agent trip details] Rider receipt creat failed');

export const partnerFetched = createAction(
  '[Support agent trip details] Partner fetched',
  props<{ partner: PartnerCompanyAggregate }>(),
);

export const allBusinessTripsButtonClicked = createAction(
  '[Support agent trip details] All business trip-list button clicked',
  props<{ businessId: string }>(),
);

export const riderDetailsButtonClicked = createAction(
  '[Support agent trip details] Rider details button clicked',
  props<{ riderId: string }>(),
);

export const quoteFetched = createAction(
  '[Support agent trip details] Booking quote fetched',
  props<{ quote: Quote }>(),
);

export const firebaseUsersFetched = createAction(
  '[Support agent trip details] Firebase users fetched',
  props<{ users: FirebaseUser[] }>(),
);

export const riderFetched = createAction(
  '[Support agent trip details] Rider fetched',
  props<{ rider: RiderAggregate }>(),
);

export const claimsDriversFetched = createAction(
  '[Support agent trip details] Claim drivers fetched',
  props<{ drivers: DriverAggregate[] }>(),
);

export const driverTelemetryFetched = createAction(
  '[Support agent trip details] Driver telemetry fetched',
  props<{ telemetry: DriverTelemetry }>(),
);

export const driverEtaFetched = createAction(
  '[Support agent trip details] Driver ETA fetched',
  props<{ eta: DriverEta }>(),
);

export const vehiclesFetched = createAction(
  '[Support agent trip details] Vehicles fetched',
  props<{ vehicles: VehicleAggregate[] }>(),
);
